<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col d-none d-lg-block col-lg-6 col-xl-5 border-bottom border-bottom-xl-0">
        <sunbed-image
          :equipment="equipment"
        />
      </div>

      <div class="col-12 col-lg-6 offset-xl-1 col-xl-6 my-auto">
        <div class="navi navi-icon-circle navi-spacer-x-0 m-auto">
          <!-- begin::Notifications - Primary -->
          <li
            v-for="(notification, idx) in primaryNotifications"
            :key="`carousel.${equipment._meta.guid}.notification.primary.${idx}`"
            class="navi-item"
          >
            <notification-nav-item :notification="notification" />
          </li>
          <!-- end::Notifications - Primary -->

          <!-- begin::Notifications - Error (Critical) -->
          <template v-if="criticalErrorNotifications.length">
            <li class="navi-separator my-0" />

            <li
              v-for="(notification, idx) in criticalErrorNotifications"
              :key="`carousel.${equipment._meta.guid}.notification.error.critical.${idx}`"
              class="navi-item"
            >
              <notification-nav-item :notification="notification" />
            </li>
          </template>
          <!-- end::Notifications - Error (Critical) -->

          <!-- begin::Notifications - Error (Critical) -->
          <template v-if="nonCriticalErrorNotifications.length">
            <li class="navi-separator my-0" />

            <li
              v-for="(notification, idx) in nonCriticalErrorNotifications"
              :key="`carousel.${equipment._meta.guid}.notification.error.non-critical.${idx}`"
              class="navi-item"
            >
              <notification-nav-item :notification="notification" />
            </li>
          </template>
          <!-- end::Notifications - Error (Critical) -->

          <!-- begin::Notifications - Maintenance -->
          <template v-if="maintenanceNotifications.length">
            <li class="navi-separator my-0" />

            <li
              v-for="(notification, idx) in maintenanceNotifications"
              :key="`carousel.${equipment._meta.guid}.notification.maintenance.${idx}`"
              class="navi-item"
            >
              <notification-nav-item :notification="notification" />
            </li>
          </template>
          <!-- end::Notifications - Maintenance -->

          <!-- begin::Performance -->
          <template v-if="$auth.hasPermission('equipment.statistics.session_performance')">
            <li class="navi-separator my-0" />

            <li class="navi-item">
              <div class="navi-link rounded">
                <div class="symbol symbol-50 mr-3">
                  <div class="symbol-label">
                    <i class="icon-lg text-info">
                      <font-awesome-icon :icon="['fas', 'tachometer']" />
                    </i>
                  </div>
                </div>
                <div class="navi-text">
                  <div class="font-size-lg">Click <router-link :to="{ name: '/sunbeds/view/:guid/statistics/performance', query: { show_result: 0 } }" class="text-info text-hover-info">here</router-link> for you sunbed's most recent performance</div>
                </div>
              </div>
            </li>
          </template>
          <!-- end::Performance -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import equipmentService from '@/libs/equipment';

import sunbedImage from '@/components/pages/sunbeds/components/sunbed-image/index.vue';
import notificationNavItem from '@/components/pages/sunbeds/view/components/notification.nav-item.vue';

export default {
  components: {
    sunbedImage,
    notificationNavItem,
  },

  computed: {
    equipment () {
      return this.$store.get('sunbeds:view:equipment');
    },
    equipmentMaintenance () {
      return this.$store.get('sunbeds:view:equipment:maintenance');
    },
    device () {
      return this.$store.get('sunbeds:view:device');
    },

    primaryNotifications () {
      return this.notifications.filter((notification) => ['info', 'status'].includes(notification.type), []);
    },
    maintenanceNotifications () {
      return this.notifications.filter((notification) => ['maintenance'].includes(notification.type), []);
    },
    errorNotifications () {
      return this.notifications.filter((notification) => ['error'].includes(notification.type), []);
    },
    criticalErrorNotifications () {
      return this.errorNotifications.filter((notification) => { // eslint-disable-line
        return notification.payload && notification.payload.critical === true;
      });
    },
    nonCriticalErrorNotifications () {
      return this.errorNotifications.filter((notification) => { // eslint-disable-line
        return !notification.payload || notification.payload.critical !== true;
      });
    },

    notifications () {
      return equipmentService.notifications(this.equipment, this.device, {
        maintenance: this.equipmentMaintenance,
      });
    },
  },
};
</script>
